(function($, Drupal, once) {

  $mobileBreakpoint = 900;

  Drupal.behaviors.header = {
    attach: function(context, settings) {

      // Mobile menu functionality
      $(window).resize(function() {
        var more = document.getElementById("js-navigation-more");
        if ($(more).length > 0) {
          var windowWidth = $(window).width();
          var moreLeftSideToPageLeftSide = $(more).offset().left;
          var moreLeftSideToPageRightSide = windowWidth - moreLeftSideToPageLeftSide;

          if (moreLeftSideToPageRightSide < 330) {
            $("#js-navigation-more .submenu .submenu").removeClass("fly-out-right");
            $("#js-navigation-more .submenu .submenu").addClass("fly-out-left");
          }

          if (moreLeftSideToPageRightSide > 330) {
            $("#js-navigation-more .submenu .submenu").removeClass("fly-out-left");
            $("#js-navigation-more .submenu .submenu").addClass("fly-out-right");
          }
        }
      });

      $(document).ready(function() {
        var menuToggle = $("#js-mobile-menu").unbind();
        $("#js-navigation-menu").removeClass("show");

        menuToggle.on("click", function(e) {
          e.preventDefault();
          $('.search-section').removeAttr('style');

          $(".mobile-nav").slideToggle(function(){
            menuToggle.find('.fal').removeClass('fa-bars').addClass('close');
            $('html, body').addClass('scroll-lock');
            if($(".mobile-nav").is(":hidden")) {
              $(".mobile-nav").removeAttr("style");
              menuToggle.find('.fal').removeClass('close').addClass('fa-bars');
              $('html, body').removeClass('scroll-lock');
            }
          });
        });
      });

      // Opens the search bar
      $('#js-search-trigger').on("click", function(e) {
        e.preventDefault();
        if($(window).width() < $mobileBreakpoint) {
          $('.search-section').animate({width: 'toggle'},function() {
            if($(this).is(":hidden")) {
              $('.search-section').removeAttr('style');
            }
            $(this).find("#edit-keys").focus();
            /* Hide mobile nav when opening search */
            $(".mobile-nav").removeAttr("style");
            $("#js-mobile-menu").find('.fal').removeClass('close').addClass('fa-bars');
            $('html, body').removeClass('scroll-lock');

          });
        } else {
          $('.search-section').animate({height: 'toggle'},function() {
            if($(this).is(":hidden")) {
              $('.search-section').removeAttr('style');
            }
            $(this).find("#edit-keys").focus();
          });
        }

      });

      // Closes the search bar.
      $('#js-search-close').on('click', function(e) {
        e.preventDefault();
        if($(window).width() < $mobileBreakpoint) {
          $('.search-section').animate({width: 'toggle'},function() {
            if($(this).is(":hidden")) {
              $('.search-section').removeAttr('style');
            }
          });
        } else {
          $('.search-section').animate({height: 'toggle'},function() {
            if($(this).is(":hidden")) {
              $('.search-section').removeAttr('style');
            }
          });
        }
      });
    }
  };

  Drupal.behaviors.attachViewExpandClasses = {
    attach: function(context, settings) {
      $(document).ready(function() {

        var $expandingViews = $('.js-expand-view');

        $expandingViews.each(function() {
          var $rows = $(this).find('.views-row');
          if($rows.length > 3) {
            $slicedRows = $rows.slice(3);
            $slicedRows.addClass('js-expand-element');
          }
        });
      });
    }
  };

  Drupal.behaviors.accordionExpand = {
    attach: function(context, settings) {
      $(document).ready(function() {

        $(".js-accordion-expand").each(function() {
          var $expand = $(this);
          $expand.addClass('close');
          $expand.find('.js-accordion-expand-trigger').click(function() {

            if($(window).width() < $mobileBreakpoint) {

              var $expandElement = $expand.find('.js-accordion-expand-element');
              if($expand.hasClass('close')) {
                $expand.removeClass('close').addClass('open');
                $expandElement.slideDown();
              }
              else {
                $expand.removeClass('open').addClass('close');
                $expandElement.slideUp();
              }
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.expand = {
    attach: function(context, settings) {
      $(document).ready(function() {

        $(".js-expand").each(function() {
          var $expand = $(this);
          var $expandTrigger = $expand.find('.js-expand-trigger');
          $expand.addClass('close');
          $expandTrigger.addClass('close');
          $expand.find('.js-expand-trigger').click(function() {
            var $expandElement = $expand.find('.js-expand-element');
            if($expand.hasClass('close')) {
              $expand.removeClass('close').addClass('open');
              $expandTrigger.removeClass('close').addClass('open');
              $expandElement.slideDown();
            }
            else {
              $expand.removeClass('open').addClass('close');
              $expandTrigger.removeClass('open').addClass('close');
              $expandElement.slideUp();
            }
          });
        });

        /* Expand "members" tab on mobile if going direct to members */
        if ((window.location.hash == '#members') && ($(window).width() < $mobileBreakpoint)) {
          $("#members .js-accordion-expand-trigger").click();
        }

      });
    }
  };

  Drupal.behaviors.previewSwitch = {
    attach: function(context, settings) {
      var $switchTrigger = $('.js-card-list-trigger');
      var $list = $('.js-card-list');
      if($switchTrigger.length) {
        $switchTrigger.click(function() {
          var $viewsCols = $list.find('.views-col, .views-row');
          var $viewContent = $list.find('.view-content');

          if($viewContent.hasClass('list-col')) {
            $viewContent.removeClass('list-col');
            $switchTrigger.text('List View');
          } else {
            $viewContent.addClass('list-col');
            $switchTrigger.text('Grid View');
          }
        });
      }
    }
  };

  Drupal.behaviors.researchCardShowAllLeads = {
    attach: function(context, settings) {
      $('.research-result .field--name-field-leads .field__item:nth-child(4):not(:last-child)')
        .after('<a href="#" class="show-leads">...more</a>');
      $(".show-leads").on('click', function(e) {
        $(this).siblings().css({'display':'inline-block'});
        $(this).hide();
        return false;
      })
    }
  };

  Drupal.behaviors.stickyNav = {
    attach: function(context, settings) {
      var $stickyNav = $('.js-sticky-nav');
      if($stickyNav.length) {
        $navPlaceholder = $('.sticky-nav-placeholder');

        if($('#toolbar-administration').length) {
          $stickyNav.css('top', '80px');
        }

        $(window).on('load', function() {
          var top = $stickyNav.offset().top;
          $navPlaceholder.css('height', $stickyNav.height());

          if($(document).scrollTop() > top) {
            $stickyNav.addClass('sticky');
            $navPlaceholder.removeClass('hidden');
          }
          else {
            $stickyNav.removeClass('sticky');
            $navPlaceholder.addClass('hidden');
          }

          $(document).on('scroll', function(e) {

            if($(document).scrollTop() > top) {
              $stickyNav.addClass('sticky');
              $navPlaceholder.removeClass('hidden');
            }
            else {
              $stickyNav.removeClass('sticky');
              $navPlaceholder.addClass('hidden');
            }
          });
        });
      }
    }
  };

  Drupal.behaviors.contactModal = {
    attach: function(context, settings) {

      var $modal = $('.modal-inner');
      var $tabs = $('.modal-inner .js-tab');
      var modalPadding = parseInt($modal.css('padding-left')) + parseInt($modal.css('padding-right'));
      var tabsWidth = 0;

      $("#contact-modal").on("change", function() {
        if ($(this).is(":checked")) {
          $("body").addClass("modal-open");
        } else {
          $("body").removeClass("modal-open");
        }

        // Gets the combined width of all the tabs inside modal.
        if ($tabs.length) {
          tabsWidth = 0;
          $tabs.each(function(i, val) {
            tabsWidth += $(this).outerWidth(true);
          });

          if ($(window).width() > $mobileBreakpoint) {
            if (tabsWidth < 600) {
              tabsWidth = 600;
            }
          } else {
            tabsWidth = $(window).width() * 0.8;
          }

          // Applies tabs width and padding to width of modal.
          $modal.css('width', tabsWidth + modalPadding + 5);
        }
      });

      $(".modal-fade-screen, .modal-close").on("click", function() {
        $(".modal-state:checked").prop("checked", false).change();
      });

      $(".modal-inner").on("click", function(e) {
        e.stopPropagation();
      });
    }
  };

  Drupal.behaviors.idTabList = {
    attach: function(context, settings) {
      var $tabPane = $('.js-tab-pane');
      var $tabMenu = $('.js-tab-menu');
      if($tabMenu.length) {
        var $firstTabMenu = $tabMenu.find('.tab:first-child');
        $firstTabMenu.addClass('active');
        var firstId = $firstTabMenu.attr('for');
        $('#' + firstId).addClass('open');

        var $tab = $tabMenu.find('.tab');

        $tab.on('click', function() {
          var id = $(this).attr('for');

          if(!$(this).hasClass('active')) {
            $tabMenu.find('.active').removeClass('active');
            $tabPane.find('.open').removeClass('open');
            $tabPane.find('#' + id).addClass('open');
            $(this).addClass('active');
          }
        });
      }
    }
  };

  Drupal.behaviors.anchorScroll = {
    attach: function(context, settings) {
      var $root = $('html, body');
      var $stickyNav = $('.js-sticky-nav');
      var offset = 0;

      if ($stickyNav.length) {
        offset = $stickyNav.outerHeight();
      }
      if ($(window).width() < $mobileBreakpoint) {
        offset = $('#site-header').outerHeight();
      }

      $(window).on('load', function() {
        if (window.location.hash) {
          var href = window.location.hash;
          $root.animate({
            scrollTop: $(href).offset().top - offset
          }, 500);
        }
      });

      $('a[href^="#"]:not(.show-leads)').click(function(e) {
        e.preventDefault();
        var href = $.attr(this, 'href');
        if($(href).length) {
          $root.animate({
            scrollTop: $(href).offset().top - offset
          }, 500);

          if (history.pushState) {
            history.pushState(null,null,href);
          } else {
            location.hash = href;
          }
        }
        return false;
      });
    }
  };

  Drupal.behaviors.contentPageMobileDropdown = {
    attach: function(context, settings) {
      var $mobileDropdown = $('.js-mobile-dropdown');

      if($mobileDropdown.length) {
        var $trigger = $mobileDropdown.find('li.menu-item--active-trail > a');

        $trigger.on('click', function(e) {
          var $menu = $(this).parent();
          var $hiddenSubnav = $mobileDropdown.find('li.menu-item--active-trail li');

          if($(window).width() < $mobileBreakpoint) {
            if($menu.hasClass('open')) {
              $menu.removeClass('open');
              $hiddenSubnav.hide();
            }
            else {
              $menu.addClass('open');
              $hiddenSubnav.show();
            }
            return false;
          }
        });

        $(window).resize(function() {
          if($(this).width() > $mobileBreakpoint) {
            $mobileDropdown.find('.menu-item').removeAttr('style');
          }
        });
      }
    }
  };

  /*
  Drupal.behaviors.filtersExpand = {
    attach: function(context, settings) {
      $openFilters = $('.js-filters-open');
      $closeFilters = $('.js-filters-close');
      $filters = $('.view-filters');
      $contentColumn = $('.landing-content-column');

      $(window).on('load', function() {
        if($(window).width() > $mobileBreakpoint) {
          $filters.addClass('open');
          $openFilters.addClass('hidden');
          $contentColumn.addClass('push');
        }
      });

      $closeFilters.click(function() {
        $filters.hide('slide', {direction: 'left'}).removeClass('open');
        $openFilters.removeClass('hidden');
        $contentColumn.removeClass('push');
      });

      $openFilters.click(function() {
        $filters.addClass('open').show('slide', {direction: 'left'});
        $(this).addClass('hidden');
        $contentColumn.addClass('push');
      });
    }
  };
  */

  Drupal.behaviors.spotlightNews = {
    attach: function(context, settings) {
      var $spotlight = $('.spotlight');
      var screenSize = ($(window).width() < $mobileBreakpoint) ? 'mobile' : 'desktop';

      if($spotlight.length) {
        $(window).on('load', function() {
          if($(window).width() < $mobileBreakpoint) {
            $spotlight.addClass('owl-carousel');
            $spotlight.owlCarousel({
              autoWidth: true,
              dotsContainer: '.spotlight-pager',
              items: 1,
              margin: 30,
              navigation: true,
              nestedItemSelector: 'spotlight-item',
              loop: false
            });
          } else {
            $spotlight.addClass('tabs-pane');
            spotlightTabs();
          }
        });

        $(window).resize(function() {
          if($(window).width() < $mobileBreakpoint && screenSize == 'desktop') {
            $spotlight.addClass('owl-carousel');
            $spotlight.owlCarousel({
              autoWidth: true,
              dotsContainer: '.spotlight-pager',
              items: 1,
              margin: 30,
              navigation: true,
              nestedItemSelector: 'spotlight-item',
              loop: false
            });
            screenSize = 'mobile';
            $spotlight.removeClass('tabs-pane');
          } else if ($(window).width() > $mobileBreakpoint && screenSize == 'mobile') {
            $spotlight.addClass('tabs-pane').removeClass('owl-carousel');
            screenSize = 'desktop';
            var items = $spotlight.find('.spotlight-item').detach();
            items.prependTo('.spotlight-items');
            $spotlight.owlCarousel('destroy');
          }
        });
      }

      function spotlightTabs() {
        var $tabPane = $('.spotlight.tabs-pane');
        var $tabMenu = $('.spotlight-tabs');
        if($tabMenu.length) {
          var $firstTabMenu = $tabMenu.find('.tab:first-of-type');
          $firstTabMenu.addClass('active');
          var firstId = $firstTabMenu.attr('for');
          $('#' + firstId).addClass('open');

          var $tab = $tabMenu.find('.tab');

          $tab.on('mouseenter', function() {
            var id = $(this).attr('for');

            if(!$(this).hasClass('active')) {
              $tabMenu.find('.active').removeClass('active');
              $tabPane.find('.open').removeClass('open');
              $tabPane.find('#' + id).addClass('open');
              $(this).addClass('active');
            }
          });
        }
      }
    }
  };

  Drupal.behaviors.calendar = {
    attach: function(context, settings) {
      $(document).ready(function() {

        if($('#event-mini-calendar').length != 0) {
          var searchString = decodeURIComponent(window.location.search);

          var match = searchString.match(/[^=&?]+\s*=\s*[^&#]*/g);
          var vars = {};
          if(match !== null) {
            for ( var i = match.length; i--; ) {
              var spl = match[i].split("=");
              var name = spl[0].replace("[]", "");
              var value = spl[1];

              vars[name] = vars[name] || [];
              vars[name].push(value);
            }
          }

          var selected = (vars['date[min]'] !== undefined) ? vars['date[min]'][0] : moment().format('YYYY-MM-DD');

          $('#event-mini-calendar').clndr({
            template: $('#template-calendar').html(),
            selectedDate: selected,
            startWithMonth: selected,
            clickEvents: {
              click: function(target) {
                var today = moment().format('YYYY-MM-DD');
                var targetDate = target.date.format('YYYY-MM-DD');
                var minDate = targetDate;
                var maxDate = moment(targetDate).add(1, 'days').format('YYYY-MM-DD');

                vars['date[min]'] = [minDate];
                vars['date[max]'] = [minDate];
                vars['date_gt'] = [maxDate];

                var urlArr = [];
                $.each(vars, function(idx2,val2) {
                  var str = idx2 + "=" + val2;
                  urlArr.push(str);
                });

                var urlString = encodeURI(urlArr.join("&"));


                window.location = window.location.pathname + '?' + urlString;
              },
              onMonthChange: function(month) {
                $('.clndr-previous-button').text(moment(month).subtract(1, 'month').format('MMM'));
                $('.clndr-previous-button').prepend('<i class="fa fa-angle-left"></i>');
                $('.clndr-next-button').text(moment(month).add(1, 'month').format('MMM'));
                $('.clndr-next-button').append('<i class="fa fa-angle-right"></i>');
              }
            },
            ready: function() {
              /* Same as the onMonthChange() event, but with the selected month at startup */
              $('.clndr-previous-button').text(moment(selected).subtract(1, 'month').format('MMM'));
              $('.clndr-previous-button').prepend('<i class="fa fa-angle-left"></i>');
              $('.clndr-next-button').text(moment(selected).add(1, 'month').format('MMM'));
              $('.clndr-next-button').append('<i class="fa fa-angle-right"></i>');
            }
          });

          $('#event-mini-calendar').find('.today').parent().addClass('this-week');
        }
      });
    }
  };

    Drupal.behaviors.tabListNews = {
    attach: function(context, settings) {
      var $tabPane = $('.js-tab-pane-2');
      var $tabMenu = $('.js-tab-menu-2');
      if($tabMenu.length) {

        var $tab = $tabMenu.find('.tab');

        $tab.on('click', function() {
          var id = $(this).attr('for');

          $('.block-views-exposed-filter-blocknews-landing-page-page-1 form').css('top', '18px');

          $('html, body').animate({
            scrollTop: $('.layout__region--filter-bar').offset().top + 'px'
          }, 'fast');

          if(!$(this).hasClass('active')) {
            $tabMenu.find('.active').removeClass('active');
            $tabPane.find('.views-element-container').addClass('closed');
            $tabPane.find('.open').removeClass('open');
            $tabPane.find(id).addClass('open');
            $(this).addClass('active').removeClass('closed');
          }
        });
        /* Remove tab items that don't link to anything */
        $tabMenu.find('.tab').each(function() {
          var id = $(this).attr('for');
          if (!$(id).length) {
            $(this).remove();
          }
        })

      }
    }
  };

  Drupal.behaviors.searchKeywords = {
    attach: function(context, settings) {

      if($('.layout--search').length && window.location.search != '') {
        $('.layout--search .base-search-title').hide();
        $('.layout--search .block-views-blockcsail-search-block-1 .view-header h1').show();
        var keywords = $('#views-exposed-form-csail-search-page-1 #edit-keywords').attr('value');

        if($('.search-page-header').attr('data-results') != 0) {
          $('#views-exposed-form-csail-search-page-1').addClass('has-results');
        }

        $('.block-views-blockcsail-search-block-1 .view-header .keywords').text(keywords);
      } else if ($('.layout--search').length) {

      }
    }
  };

  Drupal.behaviors.mobileFacetDropdown = {
    attach: function(context, settings) {
      var $mobileDropdown = $('.js-mobile-collapse-menu');

      if($mobileDropdown.length) {
        var $placeholder = $mobileDropdown.find('.js-selected-placeholder');
        var $menu = $mobileDropdown.find('ul');

        var active = $menu.find('.facet-item .is-active').find('.facet-item__value').text();

        if(active != '') {
          $placeholder.find('.value').text(active);
        } else {
          $placeholder.find('.value').text('All');
        }


        $placeholder.click(function() {
          if($menu.hasClass('open')) {
            $menu.removeClass('open');
            $placeholder.removeClass('open');
          } else {
            $menu.addClass('open');
            $placeholder.addClass('open');
          }
        });

        $menu.find('li, a').click(function() {
          $placeholder.find('.value').text($(this).text());
          $menu.removeClass('open');
          $placeholder.removeClass('open');
        });

      }

    }
  };

  Drupal.behaviors.newsLastParagraph = {
    attach: function(context, settings) {
      $newsBottom = $('.news-bottom');

      if($newsBottom.length) {
        $bodyTexts = $newsBottom.find('.body-text');
        $.trim($bodyTexts.find('p').text);
        $trimmedBodies = $newsBottom.find('.body-text');
        $lastText = $trimmedBodies.last();
        $lastP = $lastText.find('p').last();
        $lastP.addClass('final');
      }
    }
  };

  Drupal.behaviors.anchorNav = {
    attach: function(context, settings) {
      if($('.js-anchor-nav').length) {
        // Cache selectors
        var topMenu = $(".js-anchor-nav"),
        topMenuHeight = topMenu.outerHeight(),
        // All list items
        menuItems = topMenu.find("a"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
          var t = $(this).attr("href")
          if (t.startsWith('#')) {
            var item = $(t);
            if (item.length) { return item; }
          }
        });

        // Bind to scroll
        $(window).scroll(function() {
         // Get container scroll position
         var fromTop = $(this).scrollTop()+topMenuHeight+40;

         // Get id of current scroll item
         var cur = scrollItems.map(function(){
           if ($(this).offset().top < fromTop)
             return this;
         });
         // Get the id of the current element
         cur = cur[cur.length-1];
         var id = cur && cur.length ? cur[0].id : "";
         // Set/remove active class
         menuItems
          .parent().removeAttr('class')
          .end().filter("[href='#"+id+"']").parent().addClass("active");
        });
      }
    }
  };

  Drupal.behaviors.addToCalendar = {
    attach: function(context, settings) {
      if ($(".add-to-calendar").length) {
        if (window.addtocalendar)if (typeof window.addtocalendar.start == "function")return;
        if (window.ifaddtocalendar == undefined) {
          window.ifaddtocalendar = 1;
          var d = document, s = d.createElement('script'), g = 'getElementsByTagName';
          s.type = 'text/javascript';
          s.charset = 'UTF-8';
          s.async = true;
          s.src = 'https://addtocalendar.com/atc/1.5/atc.min.js';
          var h = d[g]('body')[0];
          h.appendChild(s);
        }
      }
    }
  };

  Drupal.behaviors.mapPopup = {
    attach: function(context, settings) {
      if ($(window).width() > $mobileBreakpoint) {
        $(context).find("a.room-popup").colorbox({
                                      closeButton: false,
                                      title: function () {
                                        return $(this)[0].innerHTML;
                                      }});
      }
    }
  };

})(jQuery, Drupal);
